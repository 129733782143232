import { undoable, withBi, absorbException } from '../decorators'
import { EVENTS } from '../../../constants/bi'
import { ComponentConnection, Plugin, FormField } from '../../../constants/api-types'
import CoreApi from '../core-api'
import { SecondsToResetDefaults, SuccessActionTypes } from '../../../constants/success-settings'
import {
  ROLE_DOWNLOAD_MESSAGE,
  ROLE_FORM,
  ROLE_MESSAGE,
  ROLE_SUBMIT_BUTTON,
} from '../../../constants/roles'
import { getExtraMessageText } from '../services/form-service'
import { LinkTypes, mediaTypes } from '../../../panels/submit-settings-panel/constants'
import { MissingField, FormsFieldPreset } from '../../../constants/field-types'
import { EMPTY_EMAIL_ID, innerText, isNotEmptyEmailId } from '../../../utils/utils'
import * as _ from 'lodash'
import {
  DEFAULT_EXTERNAL_LINK_OBJECT,
  DEFAULT_LINK_OBJECT,
  DEFAULT_UPLOAD_OBJECT,
  LinkPanelTypesToActionTypes,
  UploadStatuses,
  VISIBLE_LINK_PANEL_SECTIONS,
  VISIBLE_LINK_PANEL_SECTIONS_ADI,
} from './consts/links-settings'
import RemoteApi from '../../../panels/commons/remote-api'
import { PremiumRestriction } from '../../../constants/premium'
import {
  convertPluginsToFormsPlugins,
  findPlugin,
  getPlugins,
  isNativeForm,
  removePlugin,
  updatePlugin,
} from '../plugins/utils'
import { SettingsPanelProps } from '../../../panels/form-settings-panel/components/form-settings-panel'
import translations from '../../../utils/translations'
import { PAYMENT_STATUS } from '../../../panels/form-settings-panel/components/payment/constants'
import { TABS } from '../../../panels/form-settings-panel/constants'
import { FormPlugin } from '../../../constants/plugins'
import Experiments from '@wix/wix-experiments'
import {
  ALWAYS_DISPLAY_MESSAGE,
  MessageDisplayOption,
} from '../../../panels/form-settings-panel/components/submit-message/constants'
import { getSyncedFieldsCount } from '../contact-sync/utils'
import { allowCollectionSync } from '../preset/fields/field-types-data'
import { OwnSettingsTabProps } from '../../../panels/form-settings-panel/components/settings/settings'
import { PanelFieldStatus } from '../../../panels/commons/constants/field-statuses'

export interface InitialSettingsPanelData {
  message?: string
  links?: any
  email?: string
  secondEmail?: string
  missingFields?: MissingField[]
  otherFormsNames?: string[]
  isCollectionExists?: boolean
  isRegistrationForm?: boolean
  restrictions?: PremiumRestriction
  productName?: string
  productPrice?: string
}

export interface InitialSubmitPanelData {
  message?: string
  links?: any
  missingFields?: MissingField[]
  isRegistrationForm?: boolean
  restrictions?: PremiumRestriction
  submitMessageOptionsSupported: boolean
  isResponsive: boolean
}

type GetEmailsResponse = {
  email: string
  emailId: string
  failedToSave?: boolean
}[]

export const SETTINGS_API_NAME = 'settings'

const apiPath = funcName => `${SETTINGS_API_NAME}.${funcName}`

export default class SettingsApi {
  private biLogger: BILogger
  private experiments: Experiments
  private boundEditorSDK: BoundEditorSDK
  private coreApi: CoreApi
  private remoteApi: RemoteApi
  private ravenInstance

  constructor(
    boundEditorSDK,
    coreApi: CoreApi,
    remoteApi,
    { biLogger, experiments, ravenInstance }
  ) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.remoteApi = remoteApi
    this.experiments = experiments
    this.ravenInstance = ravenInstance
  }

  public async loadInitialSubmitPanelData(
    componentRef: ComponentRef
  ): Promise<InitialSubmitPanelData> {
    const formComponentRef = await this.coreApi.findComponentByRole(componentRef, ROLE_FORM)
    const formComponentConnection = await this.coreApi.getComponentConnection(formComponentRef)
    const { restrictions } = await this.coreApi.premium.getPremiumRestrictions()
    return Promise.all([
      this.getMessage(formComponentRef),
      this.getMessage(formComponentRef, ROLE_DOWNLOAD_MESSAGE),
      this.getSubmitOptionsData(formComponentRef, formComponentConnection),
      this.getCrucialElements(formComponentRef, formComponentConnection),
      this.coreApi.isRegistrationForm(formComponentRef),
      this.coreApi.isMultiStepForm(formComponentRef),
      this.coreApi.getButtonLabel(componentRef),
      this.determinePaymentStatus(formComponentRef, restrictions),
      this.coreApi.isResponsive(),
    ]).then(
      ([
        successMessage,
        downloadMessage,
        links,
        missingFields,
        isRegistrationForm,
        isMultiStepForm,
        buttonLabel,
        { paymentStatus },
        isResponsive,
      ]) => ({
        successMessage: successMessage.text,
        downloadMessage: downloadMessage.text,
        messagePosition: successMessage.position || downloadMessage.position,
        links,
        missingFields,
        isRegistrationForm,
        isMultiStepForm,
        restrictions,
        buttonLabel,
        formComponentRef,
        formComponentConnection,
        submitComponentRef: componentRef,
        paymentStatus,
        submitMessageOptionsSupported: !isMultiStepForm,
        isResponsive,
      })
    )
  }

  public async loadSettingsTabData(
    componentRef: ComponentRef,
    componentConnection: ComponentConnection
  ): Promise<Partial<OwnSettingsTabProps>> {
    try {
      const payload = await this.getEmailsAndSiteUsers(componentRef, componentConnection)

      if (!payload) {
        throw new Error('Failed to load settings initial data')
      }

      const { emails, siteUsersData } = payload

      if (this.experiments.enabled('specs.crm.FormsEditorNewEmailNotificationsTab')) {
        const selectedSiteUsersIds = _.get(componentConnection, 'config.selectedSiteUsersIds')
        const inboxOptOut = _.get(componentConnection, 'config.inboxOptOut')

        return {
          siteUsersData,
          selectedSiteUsersIds,
          inboxOptOut,
          emails,
          settingsTabDataLoaded: PanelFieldStatus.DONE,
        }
      } else {
        return {
          emails,
          settingsTabDataLoaded: PanelFieldStatus.DONE,
        }
      }
    } catch (err) {
      return { settingsTabDataLoaded: PanelFieldStatus.FAILED }
    }
  }

  @withBi({ startEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public setSelectedSiteUsersIds(
    formComponentRef: ComponentRef,
    selectedSiteUsersIds: string[],
    _biData = {}
  ) {
    return this.setComponentConnection(
      formComponentRef,
      { inboxOptOut: false, selectedSiteUsersIds },
      false
    )
  }

  @withBi({ startEvid: EVENTS.PANELS.settingsPanel.UPDATE_EMAIL_NOTIFICATIONS_OPTION })
  public updateInboxOptOutSelection(formComponentRef: ComponentRef, optOut: boolean, _biData = {}) {
    return this.setComponentConnection(formComponentRef, { inboxOptOut: optOut })
  }

  public async loadInitialSettingsPanelData({
    componentRef,
    componentConnection,
    displayedTab,
  }: {
    componentRef: ComponentRef
    componentConnection: ComponentConnection
    displayedTab: any
  }): Promise<Partial<SettingsPanelProps>> {
    const { restrictions, currentAscendPlan } = await this.coreApi.premium.getPremiumRestrictions()

    return Promise.all([
      this.getOtherFormsNames(componentRef),
      this.getMessage(componentRef),
      this.getMessage(componentRef, ROLE_DOWNLOAD_MESSAGE),
      this.getSubmitOptionsData(componentRef, componentConnection),
      this.getCrucialElements(componentRef, componentConnection),
      this.getSubmitComponentRef(componentRef),
      this.coreApi.isCollectionExists(componentRef, componentConnection),
      this.coreApi.isWixChatInstalled(),
      this.boundEditorSDK.info.getCurrency(),
      this.determinePaymentStatus(componentRef, restrictions),
      this.boundEditorSDK.environment.getLocale(),
      this.coreApi.fields.getFieldsSortByXY(componentRef),
      this.coreApi.fetchAppConfig({ formComponentRef: componentRef }),
      this.getLabels(),
    ]).then(
      ([
        otherFormsNames,
        successMessage,
        downloadMessage,
        links,
        missingFields,
        submitComponentRef,
        isCollectionExists,
        isWixChatInstalled,
        currency,
        { paymentStatus, paymentStatusChanged },
        locale,
        fieldsOnStage,
        appConfig,
        labels,
      ]) => {
        const plugins = getPlugins(componentConnection)
        const paymentPlugin = findPlugin(plugins, FormPlugin.PAYMENT_FORM)
        const items = _.get(paymentPlugin, 'payload.items')
        const products = _.map(items, (item, id) => ({ id, ...item }))

        const showPaymentTabInFormBuilderPlugin =
          isNativeForm(plugins) || !!findPlugin(plugins, FormPlugin.PAYMENT_FORM)

        const showPaymentTabInGetSubscribersPlugin =
          this.experiments.enabled('specs.cx.FormBuilderShowPaymentTabInGetSubscribers') &&
          !!findPlugin(plugins, FormPlugin.GET_SUBSCRIBERS)

        const showPaymentTabInRegistrationFormPlugin =
          this.experiments.enabled('specs.cx.FormBuilderShowPaymentTabInRegistrationForm') &&
          !!findPlugin(plugins, FormPlugin.REGISTRATION_FORM)

        const showPaymentTab =
          !this.coreApi.isResponsive() &&
          (showPaymentTabInFormBuilderPlugin ||
            showPaymentTabInGetSubscribersPlugin ||
            showPaymentTabInRegistrationFormPlugin)

        const preset = _.get(componentConnection, 'config.preset')
        const formPresetType = _.get(componentConnection, 'config.presetType')

        const showEmailMarketingTab = !!findPlugin(plugins, FormPlugin.GET_SUBSCRIBERS)

        const secondsToResetForm = componentConnection.config.secondsToResetForm

        const settingsPanelProps: Partial<SettingsPanelProps> = {
          displayedTab,
          appConfig,
          isCollectionExists,
          preset,
          formPresetType,
          plugins: convertPluginsToFormsPlugins(plugins),
          formLabelId: componentConnection.config.formLabelId,
          selectedLabels: componentConnection.config.labels || [],
          formName: componentConnection.config.formName,
          lastValidFormName: componentConnection.config.formName,
          otherFormsNames,
          successActionType:
            componentConnection.config.successActionType || SuccessActionTypes.SHOW_MESSAGE,
          secondsToResetForm:
            componentConnection.config.secondsToResetForm || SecondsToResetDefaults.MIN,
          messageDisplayOption: _.eq(secondsToResetForm, ALWAYS_DISPLAY_MESSAGE)
            ? MessageDisplayOption.ALWAYS
            : MessageDisplayOption.CUSTOM_TIME,
          links,
          missingFields,
          successMessage: _.get(successMessage, 'text') || appConfig.content.onSubmitMessage,
          downloadMessage:
            _.get(downloadMessage, 'text') || translations.t('settings.successMessage.download'),
          restrictions,
          formComponentRef: componentRef,
          submitComponentRef: submitComponentRef,
          messagePosition: successMessage.position || downloadMessage.position,
          isWixChatInstalled,
          productId: _.get(products, '[0].id'),
          productName: _.get(products, '[0].name'),
          productPrice: _.get(products, '[0].price'),
          currency,
          paymentStatus: paymentStatus || PAYMENT_STATUS.GET_STARTED,
          paymentStatusChanged: !!paymentStatusChanged,
          selectedTab: displayedTab || TABS.MAIN,
          showPaymentTab,
          locale,
          showEmailMarketingTab,
          fieldsCount: fieldsOnStage.length,
          syncedFieldsCount: getSyncedFieldsCount(fieldsOnStage),
          currentAscendPlan,
          submitMessageOptionsSupported: !!!findPlugin(plugins, FormPlugin.MULTI_STEP_FORM),
          labels,
          doubleOptIn: _.get(componentConnection, 'config.doubleOptIn'),
          hasSubscriberField: _.some(
            fieldsOnStage,
            (field: FormField) => field.fieldType === FormsFieldPreset.GENERAL_SUBSCRIBE
          ),
        }

        return settingsPanelProps
      }
    )
  }

  public async createCollectionAndOpenPopup(
    formComponentRef: ComponentRef,
    msid: string,
    extraBiData = {}
  ) {
    const collectionId = await this.coreApi.createManualCollection(formComponentRef, extraBiData)
    this.coreApi.managePanels.openPublishSitePopup(formComponentRef, msid)
    return collectionId
  }

  @undoable()
  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public setComponentConnection(
    connectToRef: ComponentRef,
    connectionConfig,
    deepMerge = true,
    _biData = {}
  ) {
    return this.coreApi.setComponentConnection(connectToRef, connectionConfig, deepMerge)
  }

  @undoable()
  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public async setLabels(componentRef: ComponentRef, labels: string[], _biData = {}) {
    return this.coreApi.setComponentConnection(componentRef, { labels }, false)
  }

  public async saveProduct(componentRef: ComponentRef, currency: string, product: Product) {
    const componentConnection = await this.coreApi.getComponentConnection(componentRef)
    const plugins: Plugin[] = _.get(componentConnection, 'config.plugins')

    let paymentPlugin: Plugin = findPlugin(plugins, FormPlugin.PAYMENT_FORM)

    const initPaymentPlugin = () => {
      paymentPlugin = {
        id: FormPlugin.PAYMENT_FORM,
        payload: {
          currency,
          items: {
            [product.id]: {
              name: product.name,
              price: product.price,
              quantity: product.quantity || 1,
            },
          },
        },
      }
    }

    const updatePaymentPlugin = () => {
      paymentPlugin.payload.currency = currency
      paymentPlugin.payload.items[product.id] = {
        name: product.name,
        price: product.price,
        quantity: product.quantity || 1,
      }
    }

    if (paymentPlugin && paymentPlugin.payload) {
      updatePaymentPlugin()
    } else {
      const collectionId = await this.coreApi.getValidCollectionId(
        componentRef,
        _.get(componentConnection, 'config.collectionId')
      )
      if (collectionId) {
        await this.coreApi.collectionsApi.addPaymentField(collectionId)
      }
      initPaymentPlugin()
    }

    const updatedPlugins = updatePlugin(plugins, paymentPlugin)

    return this.setComponentConnection(
      componentRef,
      {
        plugins: updatedPlugins,
      },
      false
    )
  }

  public async removePlugin(componentRef: ComponentRef, pluginId: FormPlugin) {
    const componentConnection = await this.coreApi.getComponentConnection(componentRef)
    const plugins = getPlugins(componentConnection)

    return this.setComponentConnection(
      componentRef,
      {
        plugins: removePlugin(plugins, pluginId),
      },
      false
    )
  }

  public async determinePaymentStatus(
    componentRef: ComponentRef,
    restrictions: PremiumRestriction
  ): Promise<{ paymentStatus: PAYMENT_STATUS; paymentStatusChanged: boolean }> {
    const [hasConnectedPayment, componentConnection] = await Promise.all([
      this.coreApi.hasConnectedPayment(),
      this.coreApi.getComponentConnection(componentRef),
    ])
    const plugins = getPlugins(componentConnection)
    const oldPaymentStatus =
      (await _.get(componentConnection.config, 'paymentStatus')) || PAYMENT_STATUS.GET_STARTED
    const paymentPlugin = findPlugin(plugins, FormPlugin.PAYMENT_FORM)

    const getPaymentStatus = () => {
      if (!paymentPlugin || !paymentPlugin.payload) {
        return PAYMENT_STATUS.GET_STARTED
      }

      if (!restrictions.allowedPlugins[FormPlugin.PAYMENT_FORM])
        return PAYMENT_STATUS.UPGRADE_TO_ASCEND

      return hasConnectedPayment ? PAYMENT_STATUS.CONNECTED : PAYMENT_STATUS.MISSING_PAYMENT_METHOD
    }

    const paymentStatus = getPaymentStatus()
    const paymentStatusChanged =
      paymentStatus !== oldPaymentStatus &&
      oldPaymentStatus !== PAYMENT_STATUS.MISSING_PAYMENT_METHOD

    await this.coreApi.setComponentConnection(componentRef, { paymentStatus })
    return { paymentStatus, paymentStatusChanged }
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.settingsPanel.SUCCESS_ACTION_TYPE_SELECTED })
  public async setSuccessActionTypeADI(
    connectToRef: ComponentRef,
    successActionType: SuccessActionTypes,
    successLinkValue,
    showTitles,
    newSuccessMessage = '',
    _biData = {}
  ) {
    await this._setSuccessActionType(connectToRef, successActionType, successLinkValue, {
      newMessage: newSuccessMessage,
    })
    await this.coreApi.layout.updateFieldsLayoutADI(connectToRef, { showTitles })
  }

  private async _handleActionTypeChange(
    formComponentRef: ComponentRef,
    successActionType: SuccessActionTypes,
    oldSuccessActionType,
    newMessage: string
  ) {
    const isMultistep = await this.coreApi.isMultiStepForm(formComponentRef)

    switch (oldSuccessActionType) {
      case SuccessActionTypes.SHOW_MESSAGE:
        await this._removeMessage(formComponentRef)
        if (isMultistep && successActionType !== SuccessActionTypes.DOWNLOAD_DOCUMENT) {
          await this.coreApi.steps.removeThankYouStep(formComponentRef)
        }
        break
      case SuccessActionTypes.DOWNLOAD_DOCUMENT:
        await this._removeMessage(formComponentRef, ROLE_DOWNLOAD_MESSAGE)
        if (isMultistep && successActionType !== SuccessActionTypes.SHOW_MESSAGE) {
          await this.coreApi.steps.removeThankYouStep(formComponentRef)
        }
        break
    }
    switch (successActionType) {
      case SuccessActionTypes.SHOW_MESSAGE:
        if (isMultistep && oldSuccessActionType !== SuccessActionTypes.DOWNLOAD_DOCUMENT) {
          await this.coreApi.steps.restoreThankYouStep(formComponentRef, newMessage)
        } else {
          await this.coreApi.fields.restoreHiddenMessage(formComponentRef, newMessage)
        }
        break
      case SuccessActionTypes.DOWNLOAD_DOCUMENT:
        if (isMultistep && oldSuccessActionType !== SuccessActionTypes.SHOW_MESSAGE) {
          await this.coreApi.steps.restoreThankYouStep(
            formComponentRef,
            newMessage,
            ROLE_DOWNLOAD_MESSAGE
          )
        } else {
          await this.coreApi.fields.restoreDownloadDocumentMessage(formComponentRef, newMessage)
        }
        break
    }
  }

  private async _setSuccessActionType(
    formComponentRef: ComponentRef,
    successActionType: SuccessActionTypes,
    successLinkValue,
    { newMessage }: { newMessage?: string } = {}
  ) {
    const {
      config: { successActionType: oldSuccessActionType },
    } = await this.coreApi.getComponentConnection(formComponentRef)

    await this.coreApi.setComponentConnection(
      formComponentRef,
      { successActionType, successLinkValue },
      false
    )

    await this._handleActionTypeChange(
      formComponentRef,
      successActionType,
      oldSuccessActionType,
      newMessage
    )
  }

  @undoable()
  public async setSuccessActionType(
    connectToRef: ComponentRef,
    successActionType: SuccessActionTypes,
    successLinkValue,
    { newMessage }: { newMessage?: string } = {}
  ) {
    return this._setSuccessActionType(connectToRef, successActionType, successLinkValue, {
      newMessage,
    })
  }

  private async _removeMessage(componentRef: ComponentRef, role: string = ROLE_MESSAGE) {
    const get = async () => {
      const messageRef = await this.coreApi.findComponentByRole(componentRef, role)
      const messageLayout = await this.boundEditorSDK.components.layout.get({
        componentRef: messageRef,
      })
      return { messageRef, messageLayout }
    }
    const { messageRef, messageLayout } = await get()
    if (!messageLayout) {
      return
    }

    return this.coreApi.removeComponentRef(messageRef)
  }

  @undoable()
  public async handleSuccessLinkPanel(componentRef: ComponentRef, isADI = false) {
    const {
      config: { successLinkValue, successActionType },
    } = await this.coreApi.getComponentConnection(componentRef)

    let linkObject
    try {
      linkObject = await this.boundEditorSDK.editor.openLinkPanel(<any>{
        value: successLinkValue,
        visibleSections: isADI ? VISIBLE_LINK_PANEL_SECTIONS_ADI : VISIBLE_LINK_PANEL_SECTIONS,
      })
    } catch (e) {
      return {
        chosenLinkType: null,
        successLinkText: null,
        linkObject: null,
      }
    }

    const successLinkText = await this.boundEditorSDK.editor.utils.getLinkAsString({
      link: linkObject,
    })

    const chosenLinkType =
      linkObject && linkObject.type
        ? LinkPanelTypesToActionTypes[linkObject.type]
        : successActionType
    await this.setSuccessActionType(componentRef, chosenLinkType, linkObject)

    const successLinkType = await this._getLinkType(linkObject)
    const successLinkSubType = this._getLinkSubType(successLinkText, successLinkType, linkObject)

    return {
      chosenLinkType: chosenLinkType,
      successLinkText,
      linkObject,
      successLinkType,
      successLinkSubType,
    }
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.settingsPanel.CLICK_UPLOAD_BUTTON })
  public async updateDownloadSection(componentRef: ComponentRef, _biData) {
    let {
      config: { successLinkValue },
    } = await this.coreApi.getComponentConnection(componentRef)

    try {
      const uploadedObject = (await this.boundEditorSDK.editor.openMediaPanel({
        mediaType: <any>mediaTypes.DOCUMENT,
        isMultiSelect: false,
      })) as DocumentMediaResult[]

      if (uploadedObject) {
        successLinkValue.docId = _.head(uploadedObject).uri
        successLinkValue.name = _.head(uploadedObject).title
        successLinkValue.status = UploadStatuses.UPLOAD_SUCCESS
      }
    } catch (error) {
      successLinkValue.status = UploadStatuses.UPLOAD_FAILED
    }
    await this.setComponentConnection(
      componentRef,
      {
        successLinkValue: successLinkValue,
      },
      false
    )
    return successLinkValue
  }

  public async getSubmitOptionsData(
    componentRef: ComponentRef,
    componentConnection?: ComponentConnection
  ) {
    const {
      config: { successActionType, successLinkValue },
    } = componentConnection || (await this.coreApi.getComponentConnection(componentRef))

    const getText = linkObj => this.boundEditorSDK.editor.utils.getLinkAsString({ link: linkObj })

    const links = {
      [SuccessActionTypes.LINK]: {
        text: await getText(DEFAULT_LINK_OBJECT),
        object: DEFAULT_LINK_OBJECT,
      },
      [SuccessActionTypes.EXTERNAL_LINK]: {
        text: await getText(DEFAULT_EXTERNAL_LINK_OBJECT),
        object: DEFAULT_EXTERNAL_LINK_OBJECT,
      },
      [SuccessActionTypes.DOWNLOAD_DOCUMENT]: {
        object: DEFAULT_UPLOAD_OBJECT,
      },
    }

    if (successActionType !== SuccessActionTypes.SHOW_MESSAGE) {
      links[successActionType] = {
        text: await getText(successLinkValue),
        object: successLinkValue,
      }
    }
    return links
  }

  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public async setEmail(
    componentRef: ComponentRef,
    {
      emailIndex,
      newEmail,
      currentEmails,
    }: {
      emailIndex: number
      newEmail: string
      currentEmails: GetEmailsResponse
    },
    _biData = {}
  ): Promise<GetEmailsResponse> {
    try {
      if (_.get(currentEmails, `[${emailIndex}].email`) === newEmail) {
        return currentEmails
      }

      let newEmailId = null

      if (!_.isEmpty(newEmail)) {
        const { emailId } = await this.remoteApi.insertEmail(newEmail)
        newEmailId = emailId
      } else {
        if (emailIndex === 0) {
          newEmailId = EMPTY_EMAIL_ID
        }
      }

      const newEmails = _.cloneDeep(currentEmails)
      newEmails[emailIndex] = { emailId: newEmailId, email: newEmail }
      const emailIds = newEmails.map(email => email.emailId)
      await this.setComponentConnection(componentRef, { emailIds }, false)

      return newEmails
    } catch (err) {
      if (this.experiments.enabled('specs.crm.FormsEditorNewEmailNotificationsTab')) {
        const newEmails = _.cloneDeep(currentEmails)
        newEmails[emailIndex] = { emailId: 'INVALID', email: newEmail, failedToSave: true }
        return newEmails
      } else {
        throw err
      }
    }
  }

  public async deleteInactiveEmails(
    componentRef: ComponentRef,
    emails: GetEmailsResponse,
    emailsLimit: number
  ): Promise<GetEmailsResponse> {
    if (emails.length <= emailsLimit) {
      return emails
    }

    const filteredEmails = _.filter(
      emails,
      email =>
        email !== null &&
        _.get(email, 'emailId') !== EMPTY_EMAIL_ID &&
        _.get(email, 'emailId') !== ''
    )

    const updatedEmails = filteredEmails.slice(0, emailsLimit)
    const emailIds = updatedEmails.map(email => email.emailId)
    await this.setComponentConnection(componentRef, { emailIds }, false)

    return updatedEmails
  }

  @withBi({
    startEvid: EVENTS.PANELS.settingsPanel.SUCCESS_ACTION_TYPE_SELECTED,
    endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED,
  })
  public async updateMessage(
    componentRef: ComponentRef,
    { newMessage, role = ROLE_MESSAGE },
    _biData = {}
  ) {
    const messageRef = await this.coreApi.findComponentByRole(componentRef, role)
    const data = await this.boundEditorSDK.components.data.get({ componentRef: messageRef })

    return this.boundEditorSDK.components.data.update({
      componentRef: messageRef,
      data: getExtraMessageText({ data, newMessage }),
    })
  }

  public async getSubmitButtonLabel(componentRef: ComponentRef) {
    const submitButtonRef = await this.getSubmitComponentRef(componentRef)
    return this.coreApi.getButtonLabel(submitButtonRef)
  }

  public getSubmitComponentRef(componentRef: ComponentRef): Promise<ComponentRef> {
    return this.coreApi.findComponentByRole(componentRef, ROLE_SUBMIT_BUTTON)
  }

  @undoable()
  public async updateSubmitButtonLabel(componentRef: ComponentRef, newLabel: string, biData) {
    const submitButtonRef = await this.getSubmitComponentRef(componentRef)
    return this.coreApi.updateButtonLabel(submitButtonRef, newLabel, { startBi: biData })
  }

  @undoable()
  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.SECONDS_TO_RESET_UPDATED })
  public setComponentConnectionResetUpdated(
    connectToRef: ComponentRef,
    connectionConfig,
    _biData = {}
  ) {
    return this.coreApi.setComponentConnection(connectToRef, connectionConfig)
  }

  public getLabels() {
    return this.remoteApi.getLabels().catch(() => null)
  }

  public async getMessage(
    componentRef: ComponentRef,
    role: string = ROLE_MESSAGE
  ): Promise<Message> {
    const messageRef = await this.coreApi.findComponentByRole(componentRef, role)
    if (!messageRef) {
      return { text: '' }
    }
    const [{ data, layout }] = await this.boundEditorSDK.components.get({
      componentRefs: messageRef,
      properties: ['data', 'layout'],
    })
    const { x, y } = layout
    return { text: _.unescape(innerText(data.text)), position: { x, y } }
  }

  private async _getEmails(emailIds: string[]): Promise<GetEmailsResponse> {
    const isThereNonEmptyEmail = _.some(emailIds, isNotEmptyEmailId)

    if (!isThereNonEmptyEmail) {
      return emailIds.map(emailId => ({ emailId, email: '' }))
    }

    return this.remoteApi.getEmailsById(emailIds)
  }

  @absorbException()
  public async getEmailsAndSiteUsers(
    componentRef: ComponentRef,
    componentConnection?: ComponentConnection
  ): Promise<{ emails: GetEmailsResponse; siteUsersData: SiteUserData[] }> {
    const connection =
      componentConnection || (await this.coreApi.getComponentConnection(componentRef))
    const config = _.get(connection, 'config')
    const emailId = _.get(config, 'emailId')
    const secondEmailId = _.get(config, 'secondEmailId')
    const emailsIds = _.get(config, 'emailIds')
    const actualEmailsIds: string[] = emailsIds || [emailId, secondEmailId]

    const [emails, siteUsersData] = await Promise.all([
      this._getEmails(actualEmailsIds),
      this.remoteApi.getSiteUsersData(),
    ])

    const owner = _.find(siteUsersData, user => user.isOwner)

    const [firstEmail, ...restOfEmails] = emails
    let actualFirstEmail = firstEmail

    if (_.get(firstEmail, 'emailId') !== EMPTY_EMAIL_ID && !_.get(firstEmail, 'email')) {
      actualFirstEmail = { emailId: null, email: _.get(owner, 'email', '') }
    }

    return {
      emails: [actualFirstEmail, ...restOfEmails.filter(email => email.email)],
      siteUsersData,
    }
  }

  public async getCrucialElements(
    componentRef: ComponentRef,
    componentConnection?: ComponentConnection
  ): Promise<MissingField[]> {
    let connection =
      componentConnection || (await this.coreApi.getComponentConnection(componentRef))

    const { controllerRef, config } = connection

    const plugins = _.get(config, 'plugins')
    const successActionType = _.get(config, 'successActionType')

    const pluginApi = this.coreApi.plugins.withPlugins(plugins)

    if (pluginApi) {
      //TODO: Thing about different solution
      const funcName = 'getCrucialElements'

      if (pluginApi.supportApi(apiPath(funcName))) {
        return pluginApi.callApi(apiPath(funcName), componentRef, connection)
      }
    }

    // TODO: Merge this with above using the plugin system solution

    let isPreviousButtonMissingPromise = Promise.resolve(null)
    let isNextButtonMissingPromise = Promise.resolve(null)

    if (!!_.find(plugins, { id: FormPlugin.MULTI_STEP_FORM })) {
      isPreviousButtonMissingPromise = this.coreApi.steps.isPreviousButtonMissing(componentRef)
      isNextButtonMissingPromise = this.coreApi.steps.isNextButtonMissing(componentRef)
    }

    const isMessageFieldMissingPromise =
      successActionType === SuccessActionTypes.SHOW_MESSAGE
        ? this.coreApi.isFieldMissingByRole(componentRef, ROLE_MESSAGE)
        : Promise.resolve(null)
    const isDownloadMessageFieldMissingPromise =
      successActionType === SuccessActionTypes.DOWNLOAD_DOCUMENT
        ? this.coreApi.isFieldMissingByRole(componentRef, ROLE_DOWNLOAD_MESSAGE)
        : Promise.resolve(null)

    const missingFields = await Promise.all([
      isMessageFieldMissingPromise,
      isDownloadMessageFieldMissingPromise,
      this.coreApi.isFieldMissingByRole(componentRef, ROLE_SUBMIT_BUTTON),
      this.coreApi.isEmailFieldMissing(controllerRef),
      isPreviousButtonMissingPromise,
      isNextButtonMissingPromise,
    ])

    return _.filter(missingFields)
  }

  public async getOtherFormsNames(
    componentRef: ComponentRef,
    componentConnection?: ComponentConnection
  ): Promise<string[]> {
    const { controllerRef } =
      componentConnection || (await this.coreApi.getComponentConnection(componentRef))
    const controllers = _.map(
      await this.boundEditorSDK.controllers.listAllControllers(),
      ({ controllerRef }) => controllerRef
    )
    return await Promise.all(
      _.map(_.pullAllBy(controllers, [controllerRef], 'id'), async formControllerRef => {
        const formRef = await this.coreApi.findConnectedComponent(formControllerRef, ROLE_FORM)
        if (!formRef) {
          return ''
        }
        const {
          config: { formName },
        } = await this.coreApi.getComponentConnection(formRef)
        return formName
      })
    )
  }

  @withBi({ startEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public async updateFormName(
    connectToRef: ComponentRef,
    { formName }: { formName: string },
    _biData = {}
  ) {
    await this.coreApi.setComponentConnection(connectToRef, { formName })
    const {
      config: { formLabelId, labels, collectionId },
    } = await this.coreApi.getComponentConnection(connectToRef)
    const updateActions = []
    if (collectionId) {
      const validCollectionId = await this.coreApi.getValidCollectionId(connectToRef, collectionId)
      updateActions.push(
        this.coreApi.collectionsApi.updateCollectionName(validCollectionId, formName)
      )
    }
    if (formLabelId && _.includes(labels, formLabelId)) {
      updateActions.push(this.remoteApi.updateTag(formLabelId, formName))
    }
    try {
      Promise.all(updateActions)
    } catch (error) {}
  }

  private async _getLinkType(linkObject) {
    if (!_.get(linkObject, 'pageId')) {
      if (_.get(linkObject, 'url')) {
        return LinkTypes.EXTERNAL_LINK
      }
      return LinkTypes.NONE
    }

    const linkedPageRef: ComponentRef = { type: 'DESKTOP', id: linkObject.pageId.substring(1) }
    const linkData = await this.boundEditorSDK.components.data.get({
      componentRef: linkedPageRef,
    })
    return _.get(linkData, 'isPopup') ? LinkTypes.LIGHTBOX : LinkTypes.PAGE
  }

  private _getLinkSubType(successLinkText, successLinkType: LinkTypes, linkObject) {
    switch (successLinkType) {
      case LinkTypes.PAGE:
        return successLinkText
      case LinkTypes.LIGHTBOX:
        return linkObject.pageId
      case LinkTypes.EXTERNAL_LINK:
        return linkObject.url
      default:
        return null
    }
  }

  public async fixFormSync(componentRef: ComponentRef): Promise<void> {
    const fields: FormField[] = await this.coreApi.fields.getFieldsSortByXY(componentRef)
    const {
      config: { collectionId },
    } = await this.coreApi.getComponentConnection(componentRef)
    const validCollectionId = await this.coreApi.getValidCollectionId(componentRef, collectionId)
    if (!validCollectionId) {
      return
    }

    const collectionFieldKeyToFields: { [key: string]: FormField[] } = fields.reduce(
      (acc, field) => {
        const collectionFieldKey = field.collectionFieldKey
        if (collectionFieldKey) {
          acc[collectionFieldKey] = (acc[collectionFieldKey] || []).concat(field)
        }
        return acc
      },
      {}
    )
    const fieldsToUpdate: FormField[] = fields
      .filter(({ fieldType }) => allowCollectionSync(fieldType))
      .filter(field => {
        return (
          _.findIndex(
            collectionFieldKeyToFields[field.collectionFieldKey],
            _field => _field.componentRef.id === field.componentRef.id
          ) > 0 || !field.collectionFieldKey
        )
      })
    fieldsToUpdate.forEach(field => {
      field.collectionFieldKey = this.coreApi.fields.getCollectionFieldKey(
        { crmLabel: field.crmLabel },
        fields
      )
    })
    await Promise.all([
      this.coreApi.collectionsApi.addFieldsToCollection(validCollectionId, fieldsToUpdate),
      ...fieldsToUpdate.map(field =>
        this.coreApi.setComponentConnection(field.componentRef, {
          collectionFieldKey: field.collectionFieldKey,
        })
      ),
    ])
  }

  public async updateUseControllerId(componentRef: ComponentRef): Promise<void> {
    await this.coreApi.setComponentConnection(componentRef, { useControllerId: true })
  }
}
